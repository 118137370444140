import axios from "axios";
import { SERVER_URL } from "src/serverConfig";

export const clientAPI = axios.create({
  baseURL: SERVER_URL,
  // timeout: 2000,
});

clientAPI.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

clientAPI.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log("error Interceptor ==> ", error);
    if (error.response.status === 401) {
      localStorage.clear();
    }
    return Promise.reject(error);
  }
);
