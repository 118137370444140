import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientAPI } from "./axiosAnstance";
import fileDownload from "js-file-download";
import axios from "axios";
import { SERVER_URL } from "src/serverConfig";
import { getAuthHeader } from "src/jwt/config";

export const imprimerAttestationAdhesionAPI = createAsyncThunk(
  "attestations/imprimerAttestationAdhesion",
  async (idCompte) => {
    let token = localStorage.getItem("token");
    return axios
      .get(
        SERVER_URL + `/api/report/attestation-adhesion?idCompte=${idCompte}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      )
      .then((res) => {
        fileDownload(res.data, "attestation-adhesion.pdf");
      });
  }
);

const AttestationSlice = createSlice({
  name: "attestations",
  initialState: { details: {} }, // enfants: []
  reducers: {},
  extraReducers: {},
});

export default AttestationSlice.reducer;
