import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {clientAPI} from "./axiosAnstance";
import axios from "axios";
import {SERVER_URL} from "../../../serverConfig";
import {getAuthHeader} from "../../../jwt/config";
import fileDownload from "js-file-download";


export const listDemandeIndemniteApi = createAsyncThunk(
  "indemnites/demande",
  async (data) => {
    const url = `/api/indemnites/getDemandes`;
    const response = await clientAPI.get(url);
    return response.data;
  }
);
export const refuserIndemniteAPI = createAsyncThunk(
  "indemnites/refuserIndemniteAPI",
  async (data) => {
    const formData = new FormData();
    console.log('data is ::: ',data)
    formData.append("idDemande", data.idDemande);
    formData.append("motifRefus", data.motifRefus);
    console.log('Formater data ::: ',formData)
    const response = await clientAPI.post("/api/indemnites/refusDemande", formData);
    return response.data;
  }
);

export const validationIndemnitesAPI = createAsyncThunk(
  "indemnites/validationIndemnitesAPI",
  async ({ id, action }, thunkAPI) => {
    try {
      const url = `/api/indemnites/validation/${id}`;
      const response = await clientAPI.post(url);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getFileByDemandeAndTypeIndemnites = createAsyncThunk(
  "hajj/getFileByDemandeAndTypeHajj",
  async (data) => {
    return axios
      .get(
        SERVER_URL +
        `/api/indemnites/getFileByDemandeAndType/${data.idDemande}/${data.id_type_fichier}`,
        {
          headers: getAuthHeader(),
          responseType: "blob",
        }
      )
      .then((res) => {
        if (data.id_type_fichier == 6)
          fileDownload(res.data, data.libelle + ".jpeg");
        else fileDownload(res.data, data.libelle + ".pdf");
      });
  }
);
export const listIndemniteFilterAPI = createAsyncThunk(
  "indemnites/listIndemniteFilterAPI",
  async (data) => {
    //const url = `/api/colonie/listColonie`;
    const response = await clientAPI.post("/api/indemnites/listFilter",data);
    return response.data;
  }
);

export const incompteteIndemnitesAPI = createAsyncThunk(
  "indemnites/incompteteIndemnitesAPI",
  async (data) => {
    const formData = new FormData();
    console.log('data is ::: ',data)
    formData.append("idDemande", data.idDemande);
    formData.append("motifRefus", data.motifRefus);
    console.log('Formater data ::: ',formData)
    const response = await clientAPI.post("/api/indemnites/icompleteDemande", formData);
    return response.data;
  }
);

const SoutienScolaire = createSlice({
  name: "demande",
  initialState: {
    listDemande: [],
    listFichierSaved: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listDemandeIndemniteApi.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listDemande = action.payload;
      })
      .addCase(listIndemniteFilterAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listDemande = action.payload;
        console.log('le nbr est ::::: ',state.listDemande )
      })


  },
});


export const {} = SoutienScolaire.actions;

export default SoutienScolaire.reducer;
