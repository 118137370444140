import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientAPI } from "./axiosAnstance";
import fileDownload from "js-file-download";
import axios from "axios";
import { SERVER_URL } from "src/serverConfig";
import { getAuthHeader } from "src/jwt/config";

export const getAdherentDetailsAPI = createAsyncThunk(
  "adherentDetails/searchAdherent",
  async (compte) => {
    const response = await clientAPI.get("/api/compte/info/" + compte);
    return response.data;
  }
);

export const imprimerFicheAdherentAPI = createAsyncThunk(
  "adherentDetails/imprimerFicheAdherent",
  async (idCompte) => {
    let token = localStorage.getItem("token");
    return axios
      .get(SERVER_URL + `/api/report/fiche-compte?idCompte=${idCompte}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, "fiche-adherent.pdf");
      });
  }
);

const AdherentDetailsSlice = createSlice({
  name: "adherentDetails",
  initialState: { details: {} }, // enfants: []
  reducers: {
    resetAdherentDetails: (state, action) => {
      state.details = {};
    },
  },
  extraReducers: {
    [getAdherentDetailsAPI.fulfilled]: (state, action) => {
      // let listEnfants = [];
      // console.log("action.payload.conjoints ", action.payload.conjoints);
      // if (action.payload && action.payload.conjoints) {
      //   const conjointsList = action.payload.conjoints;
      //   for (let index = 0; index < conjointsList.length; index++) {
      //     const conjoint = conjointsList[index];
      //     console.log("conjoint ==> ", conjoint);
      //     listEnfants = listEnfants.concat(conjoint.enfants);
      //   }
      //   console.log("last loop ", listEnfants);
      //   state.enfants = listEnfants;
      // }
      state.details = action.payload;
    },
  },
});

export const { resetAdherentDetails } = AdherentDetailsSlice.actions;

export default AdherentDetailsSlice.reducer;
