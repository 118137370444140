import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAuthHeader } from "src/jwt/config";
import { clientAPI } from "./axiosAnstance";

export const loadFileToCotisationTableAPI = createAsyncThunk(
  "cotisation/loadFileToCotisationTable",
  async (typeFile) => {
    const response = await clientAPI.get("/api/cotisation/load/" + typeFile, {
      headers: getAuthHeader(),
    });
    return response.data;
  }
);

const CotisationSlice = createSlice({
  name: "cotisation",
  initialState: { loadingStatus: null, loadingReport: null },
  reducers: {},
  extraReducers: {
    [loadFileToCotisationTableAPI.pending]: (state, action) => {
      state.loadingStatus = "pending";
      state.loadingReport = null;
    },
    [loadFileToCotisationTableAPI.fulfilled]: (state, action) => {
      state.loadingStatus = "fulfilled";
      state.loadingReport = action.payload;
    },
    [loadFileToCotisationTableAPI.rejected]: (state, action) => {
      state.loadingStatus = "rejected";
    },
  },
});

export const {} = CotisationSlice.actions;

export default CotisationSlice.reducer;
