import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getLoggedInUserFromToken } from "src/jwt/config";
import { clientAPI } from "./axiosAnstance";

export const saveUserAPI = createAsyncThunk(
  "appUsers/saveUserAPI",
  async (data) => {
    const response = await clientAPI.post("/api/users/save", data);
    return response.data;
  }
);

export const removeUserAPI = createAsyncThunk(
  "appUsers/removeUser",
  async (idUser) => {
    const response = await clientAPI.delete(
      `/api/users/remove?idUser=${idUser}`
    );
    return response.data;
  }
);

export const getListUsersAPI = createAsyncThunk(
  "appUsers/getListUsers",
  async () => {
    const response = await clientAPI.get("/api/users/list");
    return response.data;
  }
);

export const updateUserRoleAPI = createAsyncThunk(
  "appUsers/updateUserRole",
  async (data) => {
    const response = await clientAPI.get(
      `/api/users/updaterole?idUser=${data.idUser}&idRole=${data.idRole}`
    );
    return response.data;
  }
);

const AppUsersSlice = createSlice({
  name: "appUsers",
  initialState: {
    listUsers: [],
    listUsersStatus: null,
    userToEdit: {},
    startEditing: false,
    saveUserStatus: null,
    removeUserStatus: null,
    updateUserRoleStatus: null,
  },
  reducers: {
    editUser: (state, action) => {
      state.userToEdit = action.payload;
    },
    resetEditedUser: (state, action) => {
      state.userToEdit = action.payload;
    },
    setStartEditing: (state, action) => {
      state.startEditing = action.payload;
    },
  },
  extraReducers: {
    // -------------- saveUserAPI ---------------
    [saveUserAPI.pending]: (state, action) => {
      state.saveUserStatus = "pending";
    },
    [saveUserAPI.fulfilled]: (state, action) => {
      state.saveUserStatus = "fulfilled";
      console.log(action.payload);
    },
    [saveUserAPI.rejected]: (state, action) => {
      state.saveUserStatus = "rejected";
      console.log(action.payload);
    },

    // --------- getListUsersAPI --------
    [getListUsersAPI.pending]: (state, action) => {
      state.listUsersStatus = "pending";
    },
    [getListUsersAPI.fulfilled]: (state, action) => {
      state.listUsersStatus = "fulfilled";

      let loggedUsername = getLoggedInUserFromToken();

      state.listUsers =
        action.payload &&
        action.payload.filter((e) => e.username != loggedUsername);
    },
    [getListUsersAPI.rejected]: (state, action) => {
      state.listUsersStatus = "rejected";
    },

    // --------------removeUserAPI -----------------
    [removeUserAPI.pending]: (state, action) => {
      state.removeUserStatus = "pending";
    },
    [removeUserAPI.fulfilled]: (state, action) => {
      state.removeUserStatus = "fulfilled";
    },
    [removeUserAPI.rejected]: (state, action) => {
      state.removeUserStatus = "rejected";
    },

    // --------------update user role -----------------
    [updateUserRoleAPI.pending]: (state, action) => {
      state.updateUserRoleStatus = "pending";
    },
    [updateUserRoleAPI.fulfilled]: (state, action) => {
      state.updateUserRoleStatus = "fulfilled";
    },
    [updateUserRoleAPI.rejected]: (state, action) => {
      state.updateUserRoleStatus = "rejected";
    },
  },
});

export const saveUserStatusSelector = (state) => state.appUsers.saveUserStatus;
export const getListUsersSelector = (state) => state.appUsers.listUsers;

export const { resetEditedUser, editUser, setStartEditing } =
  AppUsersSlice.actions;

export default AppUsersSlice.reducer;
