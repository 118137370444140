import { combineReducers } from "redux";
import { AdherentDetailsReducer } from "src/views/components/AdherentDetails/Api";
import paramsReducer, { navRecuer } from "../views/components/api/paramsAPI";
import adherentReducer from "../views/components/api/AdherentSlice";
import adherentDetailsReducer from "../views/components/api/AdherentDetailsSlice";
import conjointReducer from "../views/components/api/ConjointSlice";
import enfantReducer from "../views/components/api/EnfantSlice";
import adherentUpdate from "../views/components/api/AdherentUpdateSlice";
import dossierReducer from "../views/components/api/DossierNumericSlice";
import cotisationReducer from "../views/components/api/CotisationSlice";
import authenticationReducer from "../views/components/api/Auth/AuthSlice";
import soldeReducer from "../views/components/api/SoldeSlice";
import precompteReducer from "../views/components/api/PrecompteSlice";
import attestationsReducer from "../views/components/api/AttestationSlice";
import appUserReducer from "../views/components/api/UsersSlice";
import demandeCardsReducer from "../views/components/api/DemandeCardsSlice";
import StatistiquesSlice from "src/views/components/api/StatistiquesSlice";
import RecoreSlice from "src/views/components/api/RecoreSlice";
import Colonies from "../views/components/api/ColoniesSlice";
import BourceExcellence from "../views/components/api/BourceExcelenceSlice";
import Estivage from "../views/components/api/EstivageSlice";
import AidAdha from "../views/components/api/AidAdhaSlice";
import Hajj from "../views/components/api/HajjSlice";
import SoutienScolaire from "../views/components/api/SoutienScolaireSlice";
import Indemnites from "../views/components/api/IndemnitesSlice";

const reducers = combineReducers({
  adherentDetails: AdherentDetailsReducer,
  params: paramsReducer,
  adherent: adherentReducer,
  adherentDetails: adherentDetailsReducer,
  navig: navRecuer,
  conjoint: conjointReducer,
  enfant: enfantReducer,
  adherentUpdate: adherentUpdate,
  dossier: dossierReducer,
  cotisation: cotisationReducer,
  authentication: authenticationReducer,
  solde: soldeReducer,
  precompte: precompteReducer,
  attestations: attestationsReducer,
  appUsers: appUserReducer,
  demandeCards: demandeCardsReducer,
  statistiques: StatistiquesSlice,
  recore: RecoreSlice,
  colonies: Colonies,
  bourseExcellence:BourceExcellence,
  estivage:Estivage,
  aidAdha : AidAdha,
  hajj : Hajj,
  soutienScolaire : SoutienScolaire,
  indemnites: Indemnites,
});

export default reducers;
