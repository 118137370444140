import jwt_decode from "jwt-decode";

export const jwtDecoded = (token) => jwt_decode(token);

export const getRole = () => {
  const token = localStorage.getItem("token");
  if (token != null) {
    const decodedToken = jwt_decode(token);
    return decodedToken != null ? decodedToken.roles : "";
  }

  return "";
};

export const saveToken = (token) => {
  console.log("saving the token hellooo ==> ", token);
  localStorage.setItem("token", token.token); //JSON.stringify(token)
};

export const getToken = () => {
  return localStorage.getItem("token");
};

export const getProvinceFromToken = () => {
  let token = localStorage.getItem("token");

  if (token != null) {
    let res = jwt_decode(token);
    console.log("res ==> ", res);
    return res.province != "" ? res.province : null;
  }
  return null;
};

export const resetToken = () => {
  console.log("reset token");
  localStorage.removeItem("token"); //JSON.stringify(token)
};

export const getAuthHeader = () => {
  let token = localStorage.getItem("token");
  console.log("FROM localStorage is =>", token);
  if (token != null)
    return {
      Authorization: `Bearer ${token}`,
      // "Access-Control-Allow-Origin": "*",
      accept: "application/json",
      // "Content-Type": "application/x-www-form-urlencoded",
    };
  else return { keyelem: "null" };
};

export const getLoggedInUserFromToken = () => {
  let token = localStorage.getItem("token");

  if (token != null) {
    let res = jwt_decode(token);
    console.log("res ==> ", res);
    return res.sub != "" ? res.sub : null;
  }
  return null;
};
