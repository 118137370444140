import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import fileDownload from "js-file-download";
import { SERVER_URL } from "src/serverConfig";
import { clientAPI } from "./axiosAnstance";

export const getListdemandeCardAPI = createAsyncThunk(
  "demandeCards/getListdemandeCardAPI",
  async (data) => {
    const response = await clientAPI.get("/api/demandeCards/list", data);
    return response.data;
  }
);

export const demandeCardAPI = createAsyncThunk(
  "demandeCards/savedemande",
  async (data) => {
    const response = await clientAPI.post("/api/demandeCards/save", data);
    return response.data;
  }
);

export const rejectDemandeCardAPI = createAsyncThunk(
  "demandeCards/reject",
  async (idDemande) => {
    const response = await clientAPI.get(
      `/api/demandeCards/reject/${idDemande}`
    );
    return response.data;
  }
);

export const confirmeDemandeCardAPI = createAsyncThunk(
  "demandeCards/confirm",
  async (idDemande) => {
    const response = await clientAPI.get(
      `/api/demandeCards/confirm/${idDemande}`
    );
    return response.data;
  }
);

export const checkDemandeCardAPI = createAsyncThunk(
  "demandeCards/confirm",
  async (data) => {
    console.log("the =========> ", data);
    const response = await clientAPI.post(
      `/api/demandeCards/check_demande`,
      data
    );
    return response.data;
  }
);

export const printAdherentCardAPI = createAsyncThunk(
  "demandeCards/printCard",
  async (idDemande) => {
    let token = localStorage.getItem("token");
    return axios
      .get(SERVER_URL + `/api/report/print_card?idDemande=${idDemande}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, "carte-adhesion.pdf");
      });
  }
);

const DemandeCardsSlice = createSlice({
  name: "demandeCards",
  initialState: { listDemandes: [] }, // enfants: []
  reducers: {},
  extraReducers: {
    [getListdemandeCardAPI.fulfilled]: (state, action) => {
      state.listDemandes = action.payload;
      // && action.payload.filter((r) => r.situationCard != "Livrée");
    },
    // [checkDemandeCardAPI.fulfilled]: (state, action) => {
    //   state.listDemandes = action.payload;
    //   // && action.payload.filter((r) => r.situationCard != "Livrée");
    // },
  },
});

export default DemandeCardsSlice.reducer;
