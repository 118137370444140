import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import fileDownload from "js-file-download";
import { getAuthHeader } from "src/jwt/config";
import { SERVER_URL } from "src/serverConfig";
import { clientAPI } from "./axiosAnstance";

export const searchAdherentAPI = createAsyncThunk(
  "adherent/searchAdherent",
  async (data) => {
    const response = await clientAPI.post("/api/compte/search", data);
    return response.data;
  }
);

export const searchAdherentForPrecompteAPI = createAsyncThunk(
  "adherent/searchAdherentForPrecompte",
  async (data) => {
    const response = await clientAPI.post(
      "/api/compte/search_for_precompte",
      data
    );
    return response.data;
  }
);

export const searchMonthlyCreatedAdhAPI = createAsyncThunk(
  "adherent/searchMonthlyCreatedAdh",
  async (data) => {
    const response = await clientAPI.post(
      "/api/compte/precompte-by-month",
      data
    );
    return response.data;
  }
);

export const checkPprAdherentAPI = createAsyncThunk(
  "adherent/checkppr",
  async (ppr) => {
    const response = await clientAPI.get(`/api/compte/ppr/${ppr}`);
    return response.data;
  }
);

export const checkAdherentExistByCinAPI = createAsyncThunk(
  "adherent/checkcin",
  async (cin) => {
    const response = await clientAPI.get(`/api/compte/by_cin/${cin}`);
    return response.data;
  }
);

export const saveCompteAPI = createAsyncThunk(
  "adherent/saveCompte",
  async (data) => {
    const response = await clientAPI.post("/api/compte/new", data);
    return response.data;
  }
);

export const savePersoAPI = createAsyncThunk(
  "adherent/savePerso",
  async (data) => {
    const perso = JSON.stringify(data.toSave);

    const blob = new Blob([perso], {
      type: "application/json",
    });

    var formData = new FormData();

    var imagefile = data.file;
    formData.append("file", imagefile);
    formData.append("adhDto", blob);
    let token = localStorage.getItem("token");

    const response = axios.post(SERVER_URL + `/api/adherent/new`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      Accept: "application/json",
    });
    return (await response).data;
  }
);

export const saveProDataAPI = createAsyncThunk(
  "adherent/saveProData",
  async (data) => {
    const response = await clientAPI.post("/api/info_pro/new", data);
    return response.data;
  }
);

export const getProfileImageCreateAPI = createAsyncThunk(
  "adherent/getProfileImage",
  async (idAdherent) => {
    const response = await axios.get(
      SERVER_URL + "/api/fichier/profile/" + idAdherent,
      {
        responseType: "blob",
        headers: getAuthHeader(),
      }
    );
    return response.data;
  }
);

export const getAdherentFileAPI = createAsyncThunk(
  "adherent/getAdherentFile",
  async (data) => {
    const response = await axios.get(
      SERVER_URL + `/api/fichier/get_file/${data.id}/${data.typeFile}`,
      {
        responseType: "blob",
        headers: getAuthHeader(),
      }
    );

    return response.data;
  }
);

export const saveAdherentFichierAPI = createAsyncThunk(
  "adherent/savefichier",
  async (data) => {
    var formData = new FormData();

    var theFile = data.file;

    formData.append("file", theFile);
    let token = localStorage.getItem("token");

    const response = axios.post(
      SERVER_URL + `/api/dossier/save_file/${data.idCompte}/${data.idFichier}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        Accept: "application/json",
      }
    );
    return (await response).data;
  }
);

const AdherentSlice = createSlice({
  name: "adherent",
  initialState: {
    pprExist: false,
    cinExist: false,
    adherentsList: [],
    adherentsListForPrecompte: [],
    compte: {},
    compteStatus: null,
    personal: {},
    personalStatus: null,
    profData: {},
    profDataStatus: null,
    profileImage: null,
    profileImageStatus: null,
    totalRows: 0,
    totalRowsForPrecompte: 0,
  },
  reducers: {
    resetCompteDataStatus: (state, action) => {
      state.compteStatus = null;
    },
    resetPersoDataStatus: (state, action) => {
      state.personalStatus = null;
    },
    resetProDataStatus: (state, action) => {
      state.profDataStatus = null;
    },
    resetPprExist: (state, action) => {
      state.pprExist = false;
    },
    resetCompteCinExist: (state, action) => {
      state.cinExist = false;
    },
    resetAdherentData: (state, action) => {
      state.pprExist = false;
      state.cinExist = false;
      state.compte = {};
      state.compteStatus = null;
      state.personal = {};
      state.personalStatus = null;
      state.profData = {};
      state.profDataStatus = null;
      state.profileImage = null;
      state.profileImageStatus = null;
    },
  },
  extraReducers: {
    [searchAdherentAPI.fulfilled]: (state, action) => {
      state.adherentsList = action.payload.data;
      state.totalRows = action.payload.totalRows;
    },
    [searchAdherentForPrecompteAPI.fulfilled]: (state, action) => {
      state.adherentsListForPrecompte = action.payload.data;
      state.totalRowsForPrecompte = action.payload.totalRows;
    },

    [searchMonthlyCreatedAdhAPI.fulfilled]: (state, action) => {
      state.adherentsListForPrecompte = action.payload.data;
      state.totalRowsForPrecompte = action.payload.totalRows;
    },

    // -------------- saveCompteAPI --------------
    [saveCompteAPI.pending]: (state, action) => {
      state.compte = action.payload;
      state.compteStatus = "pending";
    },
    [saveCompteAPI.fulfilled]: (state, action) => {
      state.compte = action.payload;
      state.compteStatus = "fulfilled";
      console.log("called inside create ", action.payload);
    },
    [saveCompteAPI.rejected]: (state, action) => {
      state.compteStatus = "rejected";
    },
    // -------------- savePersoAPI --------------
    [savePersoAPI.pending]: (state, action) => {
      state.personalStatus = "pending";
    },
    [savePersoAPI.fulfilled]: (state, action) => {
      state.personal = action.payload;
      state.personalStatus = "fulfilled";
    },
    [savePersoAPI.rejected]: (state, action) => {
      state.personalStatus = "rejected";
    },
    // -------------- saveProDataAPI --------------
    [saveProDataAPI.pending]: (state, action) => {
      state.profData = action.payload;
      state.profDataStatus = "pending";
    },
    [saveProDataAPI.fulfilled]: (state, action) => {
      state.profData = action.payload;
      state.profDataStatus = "fulfilled";
    },
    [saveProDataAPI.rejected]: (state, action) => {
      state.profData = action.payload;
      state.profDataStatus = "rejected";
    },

    // -------------- checkPprAdherentAPI --------------
    [checkPprAdherentAPI.pending]: (state, action) => {
      state.pprExist = false;
    },
    [checkPprAdherentAPI.fulfilled]: (state, action) => {
      state.pprExist = action.payload;
    },
    [checkPprAdherentAPI.rejected]: (state, action) => {
      state.pprExist = false;
    },

    // -------------- checkAdherentExistByCinAPI --------------
    [checkAdherentExistByCinAPI.pending]: (state, action) => {
      state.cinExist = false;
    },
    [checkAdherentExistByCinAPI.fulfilled]: (state, action) => {
      state.cinExist = action.payload;
    },
    [checkAdherentExistByCinAPI.rejected]: (state, action) => {
      state.cinExist = false;
    },

    // -------------- getProfileImageAPI --------------
    [getProfileImageCreateAPI.pending]: (state, action) => {
      state.profileImageStatus = "pending";
      state.profileImage = null;
    },
    [getProfileImageCreateAPI.fulfilled]: (state, action) => {
      state.profileImageStatus = "fulfilled";
      state.profileImage = action.payload;
    },
    [getProfileImageCreateAPI.rejected]: (state, action) => {
      state.profileImageStatus = "pending";
    },
  },
});

export const saveCompteStatus = (state) => state.adherent.compteStatus;
export const savePersoStatus = (state) => state.adherent.personalStatus;
export const saveProDataStatus = (state) => state.adherent.profDataStatus;
export const pprExistSelector = (state) => state.adherent.pprExist;
export const cinExistSelector = (state) => state.adherent.cinExist;
export const getProfileImageCreateSelector = (state) =>
  state.adherent.profileImage;
export const getProfileImageCreateStatusSelector = (state) =>
  state.adherent.profileImageStatus;

export const {
  resetCompteDataStatus,
  resetPersoDataStatus,
  resetProDataStatus,
  resetPprExist,
  resetCompteCinExist,
  resetAdherentData,
} = AdherentSlice.actions;

export default AdherentSlice.reducer;
