import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getAuthHeader, resetToken, saveToken } from "src/jwt/config";
import { SERVER_URL } from "src/serverConfig";
import { clientAPI } from "../axiosAnstance";

export const getLoginAPI = createAsyncThunk(
  "authentication/login",
  async (compte) => {
    const response = await axios.post(SERVER_URL + "/api/authenticate", compte);
    return response.data;
  }
);

export const getResetPasswordAPI = createAsyncThunk(
  "authentication/resetPassword",
  async (compte) => {
    const response = await axios.post(
      SERVER_URL + "/api/users/reset_password",
      compte,
      {
        headers: getAuthHeader(),
      }
    );
    return response.data;
  }
);

export const registerUserAPI = createAsyncThunk(
  "authentication/register",
  async (user) => {
    const response = await axios.post(SERVER_URL + "/api/users/new", user, {
      headers: getAuthHeader(),
    });
    return response.data;
  }
);

export const getRoleListAPI = createAsyncThunk(
  "authentication/roles",
  async () => {
    const response = await clientAPI.get("/api/roles/list");
    return response.data;
  }
);

const AuthSlice = createSlice({
  name: "authentication",
  initialState: {
    loginToken: null,
    loginStatus: {},
    register: {},
    registerStatus: null,
    resetPasswordStatus: null,
    roles: [],
  },
  reducers: {
    resetUserAuthData: (state, action) => {
      state.loginToken = null;
      state.loginStatus = {};
      state.resetPasswordStatus = null;
      state.register = {};
      state.registerStatus = null;
      state.roles = [];
    },
  },
  extraReducers: {
    [getLoginAPI.pending]: (state, action) => {
      state.loginStatus = "pending";
      state.loginToken = null;
      resetToken();
    },
    [getLoginAPI.fulfilled]: (state, action) => {
      state.loginStatus = "fulfilled";
      state.loginToken = action.payload;
      console.log("the token is == ", action.payload);
      saveToken(action.payload);
    },
    [getLoginAPI.rejected]: (state, action) => {
      state.loginStatus = "rejected";
      state.loginToken = null;
      resetToken();
    },
    [getResetPasswordAPI.pending]: (state, action) => {
      state.resetPasswordStatus = "pending";
    },
    [getResetPasswordAPI.fulfilled]: (state, action) => {
      state.resetPasswordStatus = "fulfilled";
    },
    [getResetPasswordAPI.rejected]: (state, action) => {
      state.resetPasswordStatus = "rejected";
    },
    [getRoleListAPI.fulfilled]: (state, action) => {
      state.roles = action.payload; //&& action.payload.map((e) => ({ id: e.id, libelle: e.name }));
    },
    [registerUserAPI.fulfilled]: (state, action) => {
      state.register = action.payload;
      state.registerStatus = null;
    },

    [registerUserAPI.rejected]: (state, action) => {
      console.log("creation user reject ", action.payload);
      state.register = {};
      state.registerStatus = "rejected";
    },
  },
});

export const { resetUserAuthData } = AuthSlice.actions;

export default AuthSlice.reducer;
