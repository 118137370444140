import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {clientAPI} from "./axiosAnstance";
import {searchAdherentForPrecompteAPI} from "./AdherentSlice";


export const listColoniesAPI = createAsyncThunk(
  "colonies/colonie",
  async (data) => {
    const url = `/api/colonie/listColonie`;
    const response = await clientAPI.get(url);
    return response.data;
  }
);

export const listEnfantAccepteAPI = createAsyncThunk(
  "colonies/enfantAccepte",
  async (data) => {
    const url = `/api/colonie/listEnfantAccepte`;
    const response = await clientAPI.get(url);
    return response.data;
  }
);

export const listColoniesFilterAPI = createAsyncThunk(
  "colonies/colonieFilter",
  async (data) => {
    //const url = `/api/colonie/listColonie`;
    const response = await clientAPI.post("/api/colonie/listColonieFilter",data);
    return response.data;
  }
);

export const listEnfantFilterAPI = createAsyncThunk(
  "colonies/enfantFilter",
  async (data) => {
    //const url = `/api/colonie/listColonie`;
    const response = await clientAPI.post("/api/colonie/listEnfantFilter",data);
    return response.data;
  }
);
export const listColoniesValidFilterAPI = createAsyncThunk(
  "colonies/colonieValidFilter",
  async (data) => {
    const response = await clientAPI.post("/api/colonie/listColonieValidFilter",data);
    return response.data;
  }
);


export const listColoniesValideAPI = createAsyncThunk(
  "colonies/colonieValide",
  async (data) => {

    const url = `/api/colonie/listColonieValide`;

    // Making a GET request to the constructed URL
    const response = await clientAPI.get(url);

    return response.data;
  }
);

export const ColoniesValideAPI = createAsyncThunk(
  "colonies/colonieValide",
  async (data) => {

    const url = `/api/colonie/listColonieValide`;

    // Making a GET request to the constructed URL
    const response = await clientAPI.get(url);

    return response.data;
  }
);
export const saveDemandeColoniesAPI = createAsyncThunk(
  "colonies/colonieSaveDemande",
  async (data) => {
    try {
      const response = await clientAPI.post("/api/colonie/saveDemande", data);
      return response.data;
    } catch (error) {
      return 'il est un probléme au niveau de la API';
    }
  }
);
export const validationColoniesAPI = createAsyncThunk(
  "colonies/colonieValidation",
  async ({ id, action }, thunkAPI) => {
    try {
      const url = `/api/colonie/${id}/${action}`;
      const response = await clientAPI.post(url);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getDemandeByCinAPI = createAsyncThunk(
  "colonies/getDemandeByCinAPI",
  async (cin) => {
    const response = await clientAPI.get("/api/colonie/getDemandeByCin/" + cin);
    return response.data;
  }
);

export const getDemandeByCinPeriodeAPI = createAsyncThunk(
  "colonies/getDemandeByCinAndPeriodeAPI",
  async (data) => {
    const formData = new FormData();
    formData.append("idCompte", data.idCompte);
    formData.append("idPeriode", data.idPeriode);
    const response = await clientAPI.get("/api/colonie/getDemandeByCinPeriode",{
      params: {
        idCompte: data.idCompte,
        idPeriode: data.idPeriode
      }
    }) ;
    return response.data;
  }
);

export const acceptationColoniesAPI = createAsyncThunk(
  "colonies/colonieAcceptation",
  async ({ id, action }, thunkAPI) => {
    try {
      const url = `/api/colonie/${id}/${action}`;
      const response = await clientAPI.post(url);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const nbrInscritAPI = createAsyncThunk(
  "colonies/nbrInscrit",
  async ( id, thunkAPI) => {
    try {
      const url = "/api/colonie/nombreInscrit/"+ id;
      const response = await clientAPI.get(url);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);


const Colonie = createSlice({
  name: "colonie",
  initialState: {
    listColonies: [],
    listColoniesValide: [],
    listEnfantAccepte: [],
    nbrInscrit:null,
    nombreDemande:null,
    nombreDemandeCinPeriode:null,
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listColoniesAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listColonies = action.payload;
        console.log('le nbr est ::::: ',state.listColonies )
      })
      .addCase(listColoniesFilterAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listColonies = action.payload;
      })
      .addCase(listColoniesValidFilterAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listColoniesValide = action.payload;
      })
      .addCase(listColoniesValideAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listColoniesValide = action.payload;
      })
      .addCase(listEnfantAccepteAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listEnfantAccepte = action.payload;
      })
      .addCase(listEnfantFilterAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listEnfantAccepte = action.payload;
      })
      .addCase(nbrInscritAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.nbrInscrit = action.payload;
      })
      .addCase(getDemandeByCinAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.nombreDemande = action.payload;
      })
      .addCase(getDemandeByCinPeriodeAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.nombreDemandeCinPeriode = action.payload;

      })
      .addCase(validationColoniesAPI.fulfilled, (state, action) => {
      state.status = 'succeeded';
    });

  },
});


export const {} = Colonie.actions;

export default Colonie.reducer;
