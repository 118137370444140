import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientAPI } from "./axiosAnstance";

export const getListSoldeByCompteAPI = createAsyncThunk(
  "soldeSlice/getListSoldeByCompte",
  async (idCompte) => {
    const response = await clientAPI.get("/api/recover/getSolde/" + idCompte);
    return response.data;
  }
);

const SoldeSlice = createSlice({
  name: "recover",
  initialState: { loadingStatus: null, soldeList: [] },
  reducers: {},
  extraReducers: {
    [getListSoldeByCompteAPI.pending]: (state, action) => {
      state.loadingStatus = "pending";
      state.soldeList = null;
    },
    [getListSoldeByCompteAPI.fulfilled]: (state, action) => {
      state.loadingStatus = "fulfilled";
      state.soldeList = action.payload;
    },
    [getListSoldeByCompteAPI.rejected]: (state, action) => {
      state.loadingStatus = "rejected";
    },
  },
});

export const {} = SoldeSlice.actions;

export default SoldeSlice.reducer;
