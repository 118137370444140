import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {clientAPI} from "./axiosAnstance";
import axios from "axios";
import {SERVER_URL} from "../../../serverConfig";
import {getAuthHeader} from "../../../jwt/config";
import fileDownload from "js-file-download";





export const listBrancheAPI = createAsyncThunk(
  "bourse/branche",
  async (data) => {
    const url = `/api/bourse/getBranche`;
    const response = await clientAPI.get(url);
    return response.data;
  }
);

export const listAnneeAPI = createAsyncThunk(
  "bourse/annee",
  async (data) => {
    const url = `/api/bourse/getAnnee`;
    const response = await clientAPI.get(url);
    return response.data;
  }
);
export const listDemandeAPI = createAsyncThunk(
  "bourse/demande",
  async (data) => {
    const url = `/api/bourse/getDemandes`;
    const response = await clientAPI.get(url);
    return response.data;
  }
);

export const listTypeFichierAPI = createAsyncThunk(
  "bourse/typeFichier",
  async (data) => {
    const url = `/api/bourse/getTypeFichier`;
    const response = await clientAPI.get(url);
    return response.data;
  }
);

export const saveFichierImportAPI = createAsyncThunk(
  "bourse/saveFichierImportAPI",
  async (data) => {
    const fichi = JSON.stringify(data.toSave);

    const blob = new Blob([fichi], {
      type: "application/json",
    });

    var formData = new FormData();

    var theFile = data.file;

    formData.append("file", theFile);
    formData.append("fileMeta", blob);
    let token = localStorage.getItem("token");

    const response = axios.post(SERVER_URL + `/api/import/save_file`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      Accept: "application/json",
    });
    return (await response).data;
  }
);
export const saveDemandeBourseAPI = createAsyncThunk(
  "bourse/bourseSaveDemande",
  async (data) => {
    try {
      const response = await clientAPI.post("/api/bourse/bourseSaveDemande", data);
      return response.data;
    } catch (error) {
      return 'il est un probléme au niveau de la API';
    }
  }
);
export const attribueeBourseAPI = createAsyncThunk(
  "bourse/bourseAttribuée",
  async ({ id, action }, thunkAPI) => {
    try {
      const url = `/api/bourse/attribuee/${id}`;
      const response = await clientAPI.post(url);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const refuserBourseAPI = createAsyncThunk(
  "bourse/refuserBourseAPI",
  async (data) => {
    const formData = new FormData();
    console.log('data is ::: ',data)
    formData.append("idDemande", data.idDemande);
    formData.append("motifRefus", data.motifRefus);
    console.log('Formater data ::: ',formData)
    const response = await clientAPI.post("/api/bourse/refusDemande", formData);
    return response.data;
  }
);

export const incompteteBourseAPI = createAsyncThunk(
  "bourse/incompteteBourseAPI",
  async (data) => {
    const formData = new FormData();
    console.log('data is ::: ',data)
    formData.append("idDemande", data.idDemande);
    formData.append("motifRefus", data.motifRefus);
    console.log('Formater data ::: ',formData)
    const response = await clientAPI.post("/api/bourse/icompleteDemande", formData);
    return response.data;
  }
);

export const getFileByDemandeAndTypeBource = createAsyncThunk(
  "bourse/getFileByDemandeAndTypeBource",
  async (data) => {
    return axios
      .get(
        SERVER_URL +
        `/api/bourse/getFileByDemandeAndType/${data.idDemande}/${data.id_type_fichier}`,
        {
          headers: getAuthHeader(),
          responseType: "blob",
        }
      )
      .then((res) => {
        if (data.id_type_fichier == 6)
          fileDownload(res.data, data.libelle + ".jpeg");
        else fileDownload(res.data, data.libelle + ".pdf");
      });
  }
);

export const listBourseFilterAPI = createAsyncThunk(
  "bourse/bourseFilter",
  async (data) => {
    console.log('le data est :: ',data)
    const response = await clientAPI.post("/api/bourse/bourseFilter",data);
    return response.data;
  }
);
export const confirmCompte = createAsyncThunk(
  "bourse/confirmCompte",
  async (data) => {
    console.log('le data est :: ',data)
    const response = await clientAPI.post("/api/import/confirm",data);
    return response.data;
  }
);
export const listTypeFichierSavedBourseAPI = createAsyncThunk(
  "bourse/listTypeFichierSavedBourseAPI",
  async (idDemande) => {
    const response = await clientAPI.get("/api/bourse/listeTypeFichierSaved/" + idDemande);
    return response.data;
  }
);

const Bourse = createSlice({
  name: "bourse",
  initialState: {
    listBranche: [],
    listAnnee: [],
    listTypeFichier: [],
    listTypeFichierSaved:[],
    listDemande: [],
    listCompteSave:[],
    savedDemande : {},
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listBrancheAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listBranche = action.payload;
      })
      .addCase(listAnneeAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listAnnee = action.payload;
      })
      .addCase(listTypeFichierAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listTypeFichier = action.payload;
      })
      .addCase(listDemandeAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listDemande = action.payload;
        console.log('liste des demandes est :: ',action.payload )
      })
      .addCase(listBourseFilterAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listDemande = action.payload;
        console.log('liste des demandes est :: ',action.payload )
      })
      .addCase(listTypeFichierSavedBourseAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listTypeFichierSaved = action.payload;
        console.log('liste des type fichier saved est :: ',action.payload )
      })
      .addCase(saveFichierImportAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listCompteSave = action.payload;
        console.log('liste des compte saved est :: ',action.payload )
      })


  },
});


export const {} = Bourse.actions;

export default Bourse.reducer;
