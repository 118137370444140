import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import reducers from "./reducers";
import rootSaga from "../saga";
import { configureStore } from "@reduxjs/toolkit";

const storeConfig = () => {
  const sagaMiddleware = createSagaMiddleware();

  const composeEnhancers =
    typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        })
      : compose;

  // const enhancer = composeEnhancers(
  //   applyMiddleware(sagaMiddleware)
  //   // other store enhancers if any
  // );

  // const store = createStore(reducers, enhancer);
  // const store = configureStore({ reducer: reducers }, enhancer);
  const store = configureStore({ reducer: reducers });

  // sagaMiddleware.run(rootSaga);

  return store;
};

export default storeConfig;
