import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getAuthHeader } from "src/jwt/config";
import { SERVER_URL } from "src/serverConfig";
import { clientAPI } from "./axiosAnstance";

export const saveConjointsAPI = createAsyncThunk(
  "conjoint/saveConjoints",
  async (data) => {
    const response = await clientAPI.post("/api/conjoint/save", data);
    return response.data;
  }
);

export const removeConjointAPI = createAsyncThunk(
  "conjoint/removeConjoint",
  async (data) => {
    const response = await clientAPI.delete(
      `/api/conjoint/delete/${data.idConjoint}`
    );
    return response.data;
  }
);

export const getListConjointsAPI = createAsyncThunk(
  "conjoint/getListConjoints",
  async (idCompte) => {
    const response = await clientAPI.get("/api/conjoint/get/" + idCompte);
    return response.data;
  }
);

export const getParsedConjointsAPI = createAsyncThunk(
  "conjoint/getParsedConjoints",
  async (idCompte) => {
    const response = await clientAPI.get(
      `/api/conjoint/get_parsed/${idCompte}`
    );
    return response.data;
  }
);

export const getConjointProfileImageAPI = createAsyncThunk(
  "conjoint/getProfileImage",
  async (idConjoint) => {
    const response = await axios.get(
      SERVER_URL + "/api/fichier_conjoint/profile/" + idConjoint,
      {
        responseType: "blob",
        headers: getAuthHeader(),
      }
    );
    return response.data;
  }
);

export const getConjointFileAPI = createAsyncThunk(
  "conjoint/getconjointFile",
  async (data) => {
    const response = await axios.get(
      SERVER_URL + `/api/fichier_conjoint/get/${data.id}/${data.typeFile}`,
      {
        responseType: "blob",
        headers: getAuthHeader(),
      }
    );
    return response.data;
  }
);

export const listTypeFichierSavedConjointAPI = createAsyncThunk(
  "fichier_conjoint/listTypeFichierSavedConjointAPI",
  async (idConjoint) => {
    const response = await clientAPI.get("/api/fichier_conjoint/listeTypeFichierSaved/" + idConjoint);
    return response.data;
  }
);

export const saveConjointFichierAPI = createAsyncThunk(
  "conjoint/savefichier",
  async (data) => {
    var formData = new FormData();

    var theFile = data.file;

    formData.append("file", theFile);
    let token = localStorage.getItem("token");

    const response = axios.post(
      SERVER_URL +
        `/api/fichier_conjoint/save/${data.idConjoint}/${data.idFichier}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        Accept: "application/json",
      }
    );
    return (await response).data;
  }
);

const ConjointSlice = createSlice({
  name: "conjoint",
  initialState: {
    listConjoints: [],
    listTypeFichierSaved: [],
    conjointToEdit: {},
    startEditing: false,
    saveConjointStatus: null,
    removeConjointStatus: null,
    parsedConjoints: [],
    parsedConjointsStatus: null,
  },
  reducers: {
    editConjoint: (state, action) => {
      state.conjointToEdit = action.payload;
    },
    resetEditedConjoint: (state, action) => {
      state.conjointToEdit = action.payload;
    },
    setStartEditing: (state, action) => {
      state.startEditing = action.payload;
    },
  },
  extraReducers: {
    // -------------- saveConjointsAPI ---------------
    [saveConjointsAPI.pending]: (state, action) => {
      state.saveConjointStatus = "pending";
    },
    [saveConjointsAPI.fulfilled]: (state, action) => {
      state.saveConjointStatus = "fulfilled";
    },
    [saveConjointsAPI.rejected]: (state, action) => {
      state.saveConjointStatus = "rejected";
    },
    // type fichier saved
    [listTypeFichierSavedConjointAPI.fulfilled]: (state, action) => {
      state.listTypeFichierSaved = action.payload;
      console.log('liste de type fichier saved est ::: ',action.payload)
    },
    // --------- getListConjointsAPI --------
    [getListConjointsAPI.pending]: (state, action) => {
      state.getListConjointStatus = "pending";
    },
    [getListConjointsAPI.fulfilled]: (state, action) => {
      state.getListConjointStatus = "fulfilled";
      state.listConjoints = action.payload;
    },
    [getListConjointsAPI.rejected]: (state, action) => {
      state.getListConjointStatus = "rejected";
    },

    // --------------removeConjointAPI -----------------
    [removeConjointAPI.pending]: (state, action) => {
      state.removeConjointStatus = "pending";
    },
    [removeConjointAPI.fulfilled]: (state, action) => {
      state.removeConjointStatus = "fulfilled";
    },
    [removeConjointAPI.rejected]: (state, action) => {
      state.removeConjointStatus = "rejected";
    },

    // --------------getParsedConjointsAPI -----------------
    [getParsedConjointsAPI.pending]: (state, action) => {
      state.parsedConjointsStatus = "pending";
    },
    [getParsedConjointsAPI.fulfilled]: (state, action) => {
      state.parsedConjointsStatus = "fulfilled";
      state.parsedConjoints = action.payload;
    },
    [getParsedConjointsAPI.rejected]: (state, action) => {
      state.parsedConjointsStatus = "rejected";
    },
  },
});

export const saveConjointStatusSelector = (state) =>
  state.conjoint.saveConjointStatus;
export const deleteConjointStatusSelector = (state) =>
  state.conjoint.removeConjointStatus;
export const getListConjoints = (state) => state.conjoint.listConjointsLocal;

export const getParsedConjoints = (state) => state.conjoint.parsedConjoints;
export const getParsedConjointsStatus = (state) =>
  state.conjoint.parsedConjointsStatus;

export const { editConjoint, resetEditedConjoint, setStartEditing } =
  ConjointSlice.actions;

export default ConjointSlice.reducer;
