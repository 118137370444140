import { call, put, takeEvery, all, delay } from "redux-saga/effects";
import axios from "axios";
import { SERVER_URL } from "src/serverConfig";

const GET_ADHERENT_DETAILS = "get_ADHERENT_DETAILS";
const SET_ADHERENT_DETAILS = "set_ADHERENT_DETAILS";
const SET_ADHERENT_DETAILS_ERROR = "set_ADHERENT_DETAILS_ERROR";

const getAdherentDetailsAC = (data) => {
  return {
    type: GET_ADHERENT_DETAILS,
    payload: data,
  };
};

function* watchGetAdherentDetailsSAGA() {
  yield takeEvery(GET_ADHERENT_DETAILS, getAdherentDetails);
}

function* getAdherentDetails(action) {
  try {
    const results = yield call(getAdherentDetailsAPI, action);
    yield put(setAdherentDetailsAC(results));
  } catch (e) {
    yield put(setAdherentDetailsErrorAC(e));
  }
}

const getAdherentDetailsAPI = (action) => {
  const compte = action.payload;
  return axios
    .get(SERVER_URL + "/api/compte/info/" + compte)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

const setAdherentDetailsAC = (data) => {
  return {
    type: SET_ADHERENT_DETAILS,
    payload: data,
  };
};

const setAdherentDetailsErrorAC = (data) => {
  return {
    type: SET_ADHERENT_DETAILS_ERROR,
  };
};

const AdherentDetailsReducer = (
  state = { details: {} }, //, enfants: []
  action
) => {
  switch (action.type) {
    case SET_ADHERENT_DETAILS: {
      // let listEnfants = [];
      // if (action.payload && action.payload.conjoints) {
      //   for (const conjoint of action.payload.conjoints) {
      //     listEnfants.concat(conjoint.enfants);
      //   }
      // }
      return Object.assign({}, state, {
        details: action.payload,
        // enfants: listEnfants,
      });
    }

    default:
      return state;
  }
};

export { getAdherentDetailsAC };

export { watchGetAdherentDetailsSAGA };

export { AdherentDetailsReducer };
