import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import fileDownload from "js-file-download";
import axios from "axios";
import { SERVER_URL } from "src/serverConfig";

export const downloadAdherentsDataAPI = createAsyncThunk(
  "statistiques/exportData",
  async () => {
    let token = localStorage.getItem("token");
    const d = new Date();
    var datestring =
      ("0" + d.getDate()).slice(-2) +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      d.getFullYear() +
      " " +
      ("0" + d.getHours()).slice(-2) +
      ":" +
      ("0" + d.getMinutes()).slice(-2);

    return axios
      .get(SERVER_URL + `/api/statistiques/export-data`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, `données-adhérents-${datestring}.xlsx`);
      });
  }
);

const StatistiquesSlice = createSlice({
  name: "statistiques",
  initialState: { details: {} }, // enfants: []
  reducers: {},
});

export default StatistiquesSlice.reducer;
